import { env } from '../../appConfig'
import getApiResponse from "../../services/getApiResponse";

export const checkAgencyAgentStatus = (travelBusinessesID, userId) => {

    return async (dispatch, getState) => {
        if(window.isVirtuoso()){
            return {
                "isActive": true
            }
        }
        const orgId = getState().agentProfile.orgId;
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const agecnyData = [];

        const config = {
            method: "GET",
            url: env.REACT_APP_PROFILE_ORG_BASE_URL + `/travelBusinesses/checkAgencyOrAgentStatus?travelBusinessesId=${travelBusinessesID}&userId=${userId}&orgId=${orgId}`,
            redirect: "follow",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                correlationId: corrId,
                flightType: flightType,
            },
        }

        const result = await getApiResponse(config);
        return result;

    }
}