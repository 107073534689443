export const env = {
    REACT_APP_API_BASE_URL : "https://tbi-gg-flightb2b.dev.tbidevelopment.ca",
    REACT_APP_STATIC_CONTENT : "https://tbi-gg-static.dev.tbidevelopment.ca",
    REACT_APP_PROFILE_ORG_BASE_URL : "https://tbi-gg-profile.dev.tbidevelopment.ca", 
    REACT_APP_FRAUD_CHECK_URL : "https://tbi-gg-fraud.dev.tbidevelopment.ca",
	SKIP_PREFLIGHT_CHECK : "true",
    REACT_APP_HEADER_URL : "https://www.travelbrandsagent.com/sso/headerAir_EN.js",
    REACT_APP_FOOTER_URL : "https://www.travelbrandsagent.com/sso/footer_EN.asp",
    REACT_APP_HEADER_URL_FRENCH : "https://www.travelbrandsagent.com/sso/headerAir_FR.js",
    REACT_APP_FOOTER_URL_FRENCH : "https://www.travelbrandsagent.com/sso/footer_FR.asp",
    REACT_APP_NEW_HEADER_URL : "https://access-dev.travelbrandsagent.com/common/header",
    REACT_APP_NEW_FOOTER_URL : "https://access-dev.travelbrandsagent.com/common/footer",
    REACT_APP_FLIGHT_LOGO_URL : "https://travel-img-assets.s3.us-west-2.amazonaws.com/common/logos/carrier-logos-48x48",
    REACT_APP_NOTIFICATION_API : "https://tbi-gg-notification.dev.tbidevelopment.ca",
    isProd:false,
    RECAPTCHA_SITE_KEY :"6LcogYQjAAAAACzH6oQ_Sve30G0M5K8ZSY_rVWFC",
    RECAPTCHA_SECRET_KEY:"6LcogYQjAAAAAKIcirHRj3OytZDjrAzyMPpi9kaw",
    AIRTABLE_SECRET_KEY:"patFzlwn6j6u0nlMn.e244c7a86a55cd914e5e8b44076a8f93938b85b4228227780abe2e1fe92cbb4a",
    AIRTABLE_URL:"https://api.airtable.com/v0/appE8BGP7cRjLAcTL",
    PAYMENT_URL:"https://tbi-gg-paymentapi.dev.tbidevelopment.ca",
    CENTRALIZED_EMAIL_URL: "https://tbi-gg-email-service.dev.tbidevelopment.ca",
    REACT_APP_API_CORE_BASE_URL : "https://tbi-gg-flightcore.dev.tbidevelopment.ca",
    TRAVELBRANDS_AGENT_URL : "https://travelbrandsagent.com/Login.aspx",
    TBIDEVELOPMENT_URL : "https://access-dev.travelbrandsagent.com/login",
    RELAY_SERVICE: "https://tbi-gg-sv-proxy.dev.tbidevelopment.ca/proxyReq",
    ACCESS_LOGIN_URL: "https://tbi-profile-service.dev.tbidevelopment.ca"
}
