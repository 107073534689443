import React, { useState } from "react";
import { env } from '../../../../src/appConfig';
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import getApiResponse from "../../../services/getApiResponse";
import { 
  setAgentAuthetication,
  setAgencyGroupId,
  setOrgId,
  setUserId,
  setAgencyId,
  setAgencyLocation,
  setAgentName,
  setAgentId,
  setAgentCode,
  setAgencyName,
  setAgentEmailId,
  setAgencyDetails,
  setAgentOldUserId } from "../../../appStore/actionCreators/agent";
import { useDispatch } from "react-redux";
import { setInternalUserLogin } from "../../../appStore/actionCreators/user";
import { useHistory } from "react-router-dom";

const VirtuosoLogin = () => {
  const [formValues, setFormValues] = useState({
    agencyPhone: "",
    agentUserName: "",
    password: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setError("");
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleLoginClick = async () => {
    if(!formValues.agencyPhone || !formValues.agentUserName || !formValues.password){
      setError("All fields are required");
      return;
    }
    setError("")
    const autheticationResult = await callForLogin();
    let agencyDetails = await callForAgencyDetails(autheticationResult);
    if (!agencyDetails) {
      setError("We are not able to verify the credentials")
      return;
    }
    const {Names: {username = "", first = "", last = ""} = {}, ContactInfo = {}} = autheticationResult || {};
    agencyDetails = {
      ...agencyDetails,
      username: username,
      firstName: first,
      lastName: last,
      oldAgentUserId: "",
      email: ContactInfo?.email?.[0]?.email
    }
    if (
      agencyDetails &&
      agencyDetails.hasOwnProperty("travelBusinessesId")
    ) {
      const {adAgencyCode = ""} = agencyDetails;
      dispatch({ type: "RESET_EVERYTHING" });
      sessionStorage.setItem("agency", adAgencyCode);
      sessionStorage.setItem("user", username);
      dispatch(setInternalUserLogin(false));
      dispatch(setAgencyDetails(agencyDetails))
      dispatch(setAgencyId(agencyDetails.travelBusinessesId));
      dispatch(setAgencyGroupId(agencyDetails.consortiumId));
      dispatch(setOrgId(agencyDetails.orgId));
      dispatch(setUserId(username));
      dispatch(setAgentId(username));
      dispatch(setAgentName(`${first} ${last}`));
      dispatch(setAgentCode(adAgencyCode));
      dispatch(setAgencyName(agencyDetails.adTravelAgencyName));
      dispatch(setAgentEmailId(ContactInfo?.email?.[0]?.email));
      dispatch(setAgencyLocation(
        {
          "countryCode" : agencyDetails.country,
          "stateCode": agencyDetails.provinceOrState
        }
      ))
      dispatch(setAgentOldUserId(agencyDetails.oldAgentUserId))
      dispatch(setAgentAuthetication(true));   
      history.push('/search') 
      return agencyDetails     
    } else {
      dispatch(setAgentAuthetication(false));
      return agencyDetails
    }
  };

  const callForLogin = async () => {
    const config = {
      method: "POST",
      url: `${env.RELAY_SERVICE}?forwardUrl=${env.ACCESS_LOGIN_URL}/agent/login`,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "cache-control": "no-cache"
      },
      data: formValues
    }
    let autheticationResult = await getApiResponse(config);
    [autheticationResult] = autheticationResult || [];
    return autheticationResult;
  }

  const callForAgencyDetails = async(autheticationResult) => {
    let agency = autheticationResult?.Agency?.intair;
    let email = autheticationResult?.ContactInfo?.email?.[0]?.email;
    const config = {
      method: "POST",
      url: `${env.REACT_APP_PROFILE_ORG_BASE_URL}/travelBusinesses/verifyVirtuoso?orgId=nTDopHgAED5SkaQGM6VKPG&adAgencyCode=${agency}`,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "cache-control": "no-cache"
      },
      data: {email}
    }
    let agencyResult = await getApiResponse(config);
    [agencyResult] = Array.isArray(agencyResult) ?  agencyResult : [];
    return agencyResult;
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        style={{
          maxWidth: 400,
          width: "100%",
          padding: 20,
          border: "1px solid #ddd",
          borderRadius: 8,
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", color: "#3f51b5", marginBottom: 10, fontSize: 20 }}
        >
          Welcome Back!
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 10, color: "#555" }}>
          Please log in to your account
        </Typography>
        <Box component="form">
  <TextField
    label="Agency Phone"
    name="agencyPhone"
    value={formValues.agencyPhone}
    onChange={handleInputChange}
    variant="outlined"
    fullWidth
    margin="normal"
    style={{fontSize: 14}}
    InputProps={{
      sx: { padding: "12px 14px", fontSize: "14px" }, // Padding only for the input element
      endAdornment: (
        <InputAdornment position="end">
        </InputAdornment>
      ),
    }}
    inputProps={{ style: { fontSize: "14px" } }}
    InputLabelProps={{ style: { fontSize: "14px" } }} // Label font size
  />
  <TextField
    label="Agent Username"
    name="agentUserName"
    value={formValues.agentUserName}
    onChange={handleInputChange}
    variant="outlined"
    fullWidth
    margin="normal"
    InputProps={{
      sx: { padding: "12px 14px", fontSize: "14px" },
      endAdornment: (
        <InputAdornment position="end">
        </InputAdornment>
      ),
    }}
    inputProps={{ style: { fontSize: "14px" } }}
    InputLabelProps={{ style: { fontSize: "14px" } }}
  />
  <TextField
    label="Password"
    name="password"
    value={formValues.password}
    onChange={handleInputChange}
    type={showPassword ? "text" : "password"}
    variant="outlined"
    fullWidth
    margin="normal"
    InputProps={{
      sx: { padding: "12px 14px", fontSize: "14px" },
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={togglePasswordVisibility} edge="end">
            <FontAwesomeIcon style={{fontSize: "20px"}} icon={showPassword ? faEyeSlash : faEye} />
          </IconButton>
        </InputAdornment>
      ),
    }}
    inputProps={{ style: { fontSize: "14px" } }}
    InputLabelProps={{ style: { fontSize: "14px" } }}
  />
  <Button
    variant="contained"
    color="primary"
    fullWidth
    style={{ marginTop: 30, fontSize: "14px" }}
    onClick={handleLoginClick}
  >
    Login
  </Button>
</Box>

        <Typography
          style={{ color: "red", marginTop: 20, textAlign: "center" }}
        >
          {error}
        </Typography>
      </Box>
    </Box>
  );
};

export default VirtuosoLogin;
