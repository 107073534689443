import React, { useEffect, useState, lazy } from "react";
import { useHistory , useLocation} from "react-router-dom";
import Header from "./../home/Header";
import { BookingProvider } from "./../home/bookingContext";
import BookingFor from "./../home/Bookingfor";
import { useDispatch, useSelector } from "react-redux";
import { CONFIG, TripType, taxIncreasedStatusArray } from "../../../utils/constant"
import { getPnrDetails } from "../../../appStore/thunks/getPnrDetails";
import { setBookingItinenary } from "../../../appStore/actionCreators/searchForm"
import { updatePnr } from "../../../appStore/thunks/updatePnr";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { setSeat, setPrevSeat, setEditSeat, setSeatMapData, setSaveSeat, setEditSeatRecord, setIsPassportMandatory } from "../../../appStore/actionCreators/itinenary"
import { setBookingDetails, setMarkupApplied,setItinaryFaresType, setPnr, setUpdatePnrCalculatorFlow, setPaymentRules, setPaymentRulesForCheque, setItenaryData, setInitialPaymentRules, setInitialPaymentRulesChq } from "../../../appStore/actionCreators/flightBooking"
import { getPaymentRules } from "../../../appStore/thunks/getPaymentRules";
import { PassengerType } from "../../../../src/utils/constant";
import moment from "moment";
import { env } from "../../../appConfig";
import getApiResponse from "../../../services/getApiResponse";
import queryString from 'query-string'
import { ssoAuthetication } from "../../../appStore/thunks/ssoAuthetication";
import SpinnerLoader from "./../../../components/common/spinner"
import ConfirmationHead from "./../../../components/bookingConfirmation/ConfirmationHead";
import BookingQuoteNote from "./../../../components/bookingConfirmation/BookingQuoteNote"
import Markup from "../../../components/bookings/Markup"
import BookingNumberForLandCruise from "../../../components/bookings/BookingNumberForLandCruise";
import BookingItineryDetails from '../../../components/bookings/BookingItinerary ';
import BookingItinenaryMulticity from '../../../components/bookings/BookingItineraryMulticity';
import BookingItineraryOneway from '../../../components/bookings/BookingItineraryOneway';
import { useTranslation } from "react-i18next";
import { dobAgeCheckValidation,flightCarriersDataPassportMandatory } from "../../../utils/utility/commenFunctions";
import { getRepriceBeforePayment } from "../../../appStore/thunks/getRepriceBeforePayment"; 
import { Dialog } from "@material-ui/core";
import infoIcon from '../../../Assets/infoIcon.png'
import { setAgentToken, setOrgId } from "../../../appStore/actionCreators/agent";
// import BookingConfirmationButton from "./../../../components/bookingConfirmation/BookingConfirmationButton";
// import BookingConfirmationPrice from "./../../../components/bookingConfirmation/BookingConfirmationPrice";
// import BookingPassengerDetails from "./../../../components/bookingConfirmation/BookingPassengerDetails";
// import BookingPaymentSummary from '../../../components/bookings/BookingPaymentSummary';
// import BookingTravelAgentInfo from "../../../components/bookings/BookingTravelAgentInfo";

const BookingConfirmationPrice = lazy(() => import('./../../../components/bookingConfirmation/BookingConfirmationPrice'))
const BookingPassengerDetails = lazy(() => import('./../../../components/bookingConfirmation/BookingPassengerDetails') )
const BookingConfirmationButton = lazy(() => import('./../../../components/bookingConfirmation/BookingConfirmationButton'))
const BookingPaymentSummary = lazy(() => import('../../../components/bookings/BookingPaymentSummary'))
const BookingTravelAgentInfo = lazy(() => import('../../../components/bookings/BookingTravelAgentInfo'))
const DuffelPriceSummary = lazy(() => import('./../../../components/bookings/DuffelPriceSummary'))

toast.configure();

function BookingConfirmationContainer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  let bookingItinenaries = useSelector(
    (state) => state.searchForm.bookingItinenary
  );
  const priceValue = useSelector(
    (state) => state.flightBooking.bookingItenaries
  );
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const flightDetail = useSelector((state) => state.flightBooking);
  const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
  const bookingDate = useSelector((state) => state.flightBooking.bookingDate);  
  const seat = useSelector((state) => state.itinenaries.seat);
  const paxFares =
    bookingData &&
    bookingData.itineraryFare &&
    bookingData.itineraryFare.paxFares;
  const paymentTypeDetails =
    bookingData && bookingData.payments ? bookingData.payments : [];
  const bookingStatus = useSelector(
    (state) => state.flightBooking.bookingStatus
  );
  const tripTypeFlowFrom = useSelector((state) => (state.flightBooking.tripTypeFlowFrom));
  const updatePnrCalculatorFlow = useSelector((state) => (state.flightBooking.updatePnrCalculatorFlow));
  const allData = useSelector((state) => (state.flightBooking.getPnrResult));
  const tripData = useSelector((state) => state.searchForm.tripData);
  const providerId = useSelector((state) => state.searchForm.providerId);
  const isPassportMandatory = useSelector((state) => state.itinenaries.isPassport);
  const pnrBookingDetails = useSelector(
    (state) => state.flightBooking.pnrCancellationStatus
  );
  const saveCalculatorFlow = useSelector((state)=> state.flightBooking.saveCalculatorFlow);
  let isDuffleFlow = useSelector((state)=> state.itinenaries.providerType);
  let provider = useSelector((state) => state.flightBooking.providerName);
  const [configuration,] = useState(CONFIG[provider])
  const [isload, setLoad] = useState(false);
  const [editPassenger, setEditPassenger] = useState([]);
  const [addPassenger, setAddPassenger] = useState([]);
  const [prevState, setAddedSeatList] = useState([]);
  const [editSeatStatus, setEditSeatStatus] = useState(false);
  const [consortiumName, setConsortiumName] = useState("");
  const [isUpdate, setIsUpdate] = useState("");
  const [updateOperations1, setUpdateOperations1] = useState("");
  const [ticketableDateCheck, setTicketableDateCheck] = useState(false);
  const [expandedPaymentDetails,setExpandedPaymentDetails]= useState([]);
  const [validInfo, setValidInfo] =  useState(false);
  const [nonGigiPNRCheck,setNonGigiPNRCheck] = useState(false);
  const [saveDetails, setSaveDetails]= useState(false);
  const [timeOutError, setTimeoutError] = useState(false);
  const [isPQupdatedManually, setIsPQupdatedManually] = useState(false)
  const [isPaymentDoneManually, setIsPaymentDoneManually] = useState(false)
  const [dobGenderError, setDobGenderError] = useState(false)
  const [verifyPnrPopUp, setVerifyPnrPopUp] = useState(false)
  const [fetchLatestFare, setFetchLatestFare] = useState(false)
  const [isItineraryModified, setIsItineraryModified] = useState(false)
  const [itineraryFareNotPresent, setIitineraryFareNotPresent] = useState(false)
  const [counter,setCounter]=useState(0)
  // const [isVerifyPNRFailed, setIsVerifyPNRFailed] = useState(false);
  const agencyDetails = useSelector(
    (state) => state.agentProfile.agencyDetails
  );
  const recordLocator = useSelector((state) => state.flightBooking.pnr);
  const orgId = useSelector((state) => state.agentProfile.orgId);
  const agencyId = useSelector((state) => state.agentProfile.agencyId);
  const agencyGroupId = useSelector(
    (state) => state.agentProfile.agencyGroupId
  );
  const agentDetails = useSelector((state) => state.agentProfile.agentDetails);
  const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
  const agentEmergencyNumber = useSelector((state) => state.flightBooking.agentEmergencyNumber);
  const agentOldUserId=useSelector((state) => state.agentProfile.agentOldUserId);
  const emailLanguage = useSelector((state) => state.flightBooking.emailLanguage);
  // const isVerifyPNRFailed = useSelector((state) => state.flightBooking.isVerifyPNRFailed);
  const optionDateToDisplay = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.optionDateToDisplay;
  const passengerData = bookingData?.passengers
  const segment = bookingData?.segments
  let agencyMarkups = bookingData && bookingData.agencyMarkups
  let fareTypeFromPnr = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.type;
  let adultAmount;
  let childAmount;
  let infantAmount;
  let pnrBookingStatus  = pnrBookingDetails?.bookingStatusDetail?.code;
  let productionEnvFlag = false;
  let cancellationFlag = true;
  if(pnrBookingStatus === "PT" && productionEnvFlag){
    cancellationFlag = false;
  }
  if (agencyMarkups && agencyMarkups.length > 0) {
    let adultData = agencyMarkups.filter(
      (obj) => obj.paxType === PassengerType.ADULT
    );
    let childData = agencyMarkups.filter(
      (obj) => obj.paxType === PassengerType.CHILD
    );
    let infantdata = agencyMarkups.filter(
      (obj) => obj.paxType === PassengerType.INFANTINLAP
    );
    adultAmount =
      adultData && adultData[0]
        ? adultData[0].perPaxAmount && adultData[0].perPaxAmount.amount
        : "";
    childAmount =
      childData && childData[0]
        ? childData[0].perPaxAmount && childData[0].perPaxAmount.amount
        : "";
    infantAmount =
      infantdata && infantdata[0]
        ? infantdata[0].perPaxAmount && infantdata[0].perPaxAmount.amount
        : "";
  }

  useEffect(()=>{
    if(paymentTypeDetails){
      let newPaymentTypeDetails=[];
      
      for(let i=0;i<paymentTypeDetails.length;i++){
        if(paymentTypeDetails[i].appliedTransactions){
          if(paymentTypeDetails[i].appliedTransactions.length >1){
            for(let j=0;j<paymentTypeDetails[i].appliedTransactions.length;j++){
              let arr={};
              if(paymentTypeDetails[i].cheque){
                arr={
                  type:paymentTypeDetails[i].type,
                  cheque:paymentTypeDetails[i].cheque,
                  appliedTransactions:[paymentTypeDetails[i].appliedTransactions[j]],
                  id:paymentTypeDetails[i].appliedTransactions[j].passengerRefId
                }
                newPaymentTypeDetails.push(arr)
                
                
              }else if(paymentTypeDetails[i].creditCard){
                arr={
                  type:paymentTypeDetails[i].type,
                  creditCard:paymentTypeDetails[i].creditCard,
                  appliedTransactions:[paymentTypeDetails[i].appliedTransactions[j]],
                  id:paymentTypeDetails[i].appliedTransactions[j].passengerRefId
                }
                newPaymentTypeDetails.push(arr)
                
              }
            }
          }else{
            let arr={};
            arr={
              id:paymentTypeDetails[i].appliedTransactions[0].passengerRefId,
              ...paymentTypeDetails[i]
            }
            newPaymentTypeDetails.push(arr)
          }
        }
      }
      let sort=newPaymentTypeDetails.sort((a,b)=>a.id-b.id);
      setExpandedPaymentDetails(sort);
      console.log("newPaymentTypeDetails",sort)
    }
  },[bookingData.payments])
    
  
  

  const currency =
    priceValue && priceValue.baseAmount && priceValue.baseAmount.currency;
  const adultsCount = useSelector((state) => state.flightBooking.pnrAdultCount);
  const childCount = useSelector((state) => state.flightBooking.pnrChildCount);
  const infantCount = useSelector(
    (state) => state.flightBooking.pnrInfantCount
  );
  const bookingWithQuote = useSelector(
    (state) => state.savedQuotes.bookingWithQuote
  );
  const editSeatRecord = useSelector(
    (state) => state.itinenaries.editSeatRecord
  );
  const bookingDetail = useSelector(
    (state) => state.flightBooking.bookingItenaries
  );
  let history = useHistory();

  const carrier = useSelector(
    (state) =>
      state.flightBooking &&
      state.flightBooking.bookingItenaries &&
      state.flightBooking.bookingItenaries.platingCarrierDetails &&
      state.flightBooking.bookingItenaries.platingCarrierDetails.carrier
  );

  // asyn await function for setConsortiumName(response.consortiumName)
  async function myGetApiResponse(){
    //Axios payload
    const config = {
      url: env.REACT_APP_PROFILE_ORG_BASE_URL + `/consortium?consortiumId=${agencyDetails.consortiumId}&orgId=${orgId}`,
      method: "GET",
      redirect: "follow",
    };
    const response = await getApiResponse(config);
    setConsortiumName(response.consortiumName);
  }

  useEffect(() => {
    dispatch(setMarkupApplied(true));
    dispatch(setItinaryFaresType(""));
    if(agencyDetails?.consortiumId){
      myGetApiResponse()
    }

  }, []);

  useEffect(() => {
    const currentDateTime = new Date();
    let resultDate = moment(currentDateTime).tz("America/Toronto").format();

    if (optionDateToDisplay > resultDate) {
      setTicketableDateCheck(true);
    } else {
      setTicketableDateCheck(false);
    }
  }, [optionDateToDisplay]);

  // SSO TOKEN FLOW ON CONFIRMATION PAGE
  const valueFromUrl = queryString.parse(location && location.search);
  let ssoToken = valueFromUrl && valueFromUrl.token;
  let pnrFromUrl = valueFromUrl && valueFromUrl.pnr;
  let providerIdFromUrl = valueFromUrl && valueFromUrl.provider_id;
  let orgIdFromUrl = valueFromUrl && valueFromUrl.org_id;
  


  useEffect(()=>{
    ssoAuth()
  },[ssoToken])

  const ssoAuth = async() => {
    if(ssoToken){
      
      let ssoResult = await dispatch(ssoAuthetication(orgIdFromUrl,ssoToken)) 
        if(ssoResult && ssoResult.hasOwnProperty("travelBusinessesId")){
        let agencyDetails = ssoResult
        dispatch(setPnr(pnrFromUrl))
        dispatch(setAgentToken(ssoToken))
        dispatch(setOrgId(orgIdFromUrl));
        // GET BOOKING API CALL REQUEST 
        const agencyData =
        {
          code: agencyDetails.adAgencyCode,
          name: agencyDetails.adTravelAgencyName,
          networkId: agencyDetails.adNetworkId,
          address: {
            line1: agencyDetails.adAddressLine1,
            line2: agencyDetails.adAddressLine2,
            city: {
              name: agencyDetails.adCity,
            },
            state: {
              code: agencyDetails.provinceOrState
            },
            country: {
              code: agencyDetails.country
            },
            postalCode: agencyDetails.adPostalCode,
            addressType: "Unspecified" 
          },
          phone: {
            number: agencyDetails.adNetworkId,
            type: "Unknown"
          },
          agencyGroupName: consortiumName,
          agencyGroupCode: agencyDetails.consortiumId
        };
    
        const travelAgent = {     
          firstName: agencyDetails.firstName,
          lastName: agencyDetails.lastName,
          userName: agencyDetails.username
        };

        let requestPayload = {
          recordLocator: pnrFromUrl,
          providerId: providerIdFromUrl,
          organizationId: orgIdFromUrl,
          agencyId: agencyDetails.travelBusinessesId,
          agencyGroupId: agencyDetails.consortiumId,
          agency: agencyData,
          travelAgent: travelAgent
        }

        let result = await dispatch(getPnrDetails(requestPayload))
          if (result) {
            // setLoad(true)
            setStatus(result && result.bookingStatusDetail && result.bookingStatusDetail.code)
      
            if (result.bookingDetails && result.bookingDetails.segments) {
               dispatch(setBookingItinenary(result.bookingDetails))
              //  history.push("/booking-confirmation")
            } 
            // setLoad(true)
            setIsUpdate(true)  
            let verifyPnrResult = await callVerifyPNR(result)
            
             
            
            let defaultTransaction;
            if(verifyPnrResult?.status == 'Success'){
              toast.dismiss();
              defaultTransaction = verifyPnrResult?.defaultTransactionDetails;
              dispatch(setItenaryData(verifyPnrResult?.itineraryFare))
              dispatch(setItinaryFaresType(verifyPnrResult?.itineraryFare?.type))
            } else {
              defaultTransaction = result?.defaultTransactionDetails;
              if(result?.bookingDetails?.itineraryFare){
                dispatch(setItenaryData(result?.bookingDetails?.itineraryFare))
                dispatch(setItinaryFaresType(result?.bookingDetails?.itineraryFare?.type))
              }
            }
            dispatch(setMarkupApplied(true))
            for (let index = 0; index < defaultTransaction?.length; index++) {
              defaultTransaction[index]["agencyMarkups"] = result?.bookingDetails?.agencyMarkups
              defaultTransaction[index]["itineraryFare"] = result?.bookingDetails?.itineraryFare
              if (defaultTransaction[index]?.type == "CreditCard") {
                dispatch(setPaymentRules(defaultTransaction[index]))
                dispatch(setInitialPaymentRules(defaultTransaction[index]))
              }
              else if (defaultTransaction[index]?.type == "Cheque") {
                dispatch(setPaymentRulesForCheque(defaultTransaction[index]))
                dispatch(setInitialPaymentRulesChq(defaultTransaction[index]))
              }
              else {
                dispatch(setPaymentRules(defaultTransaction[index]))
                dispatch(setInitialPaymentRules(defaultTransaction[index]))
              }
            }
            setFetchLatestFare(false)
            // setLoad(true)
          }
        }
        else{
          history.push("/")
        }
    }
  }
// SSO TOKEN FLOW ON CONFIRMATION PAGE END
  const agencyData = {
    code: agencyDetails.adAgencyCode,
    name: agencyDetails.adTravelAgencyName,
    networkId: agencyDetails.adNetworkId,
    address: {
      line1: agencyDetails.adAddressLine1,
      line2: agencyDetails.adAddressLine2,
      city: {
        name: agencyDetails.adCity,
        // code: agencyDetails.adCity
      },
      state: {
        // name: agencyDetails.provinceOrState,
        code: agencyDetails.provinceOrState,
      },
      country: {
        // name: agencyDetails.country,
        code: agencyDetails.country,
      },
      postalCode: agencyDetails.adPostalCode,
      addressType: "Unspecified", //Unspecified,Home,Business,Office,Billing,Residential,Destination
    },
    phone: {
      number: agencyDetails.adNetworkId,
      type: "Unknown", //Unknown,Fax,Home,Business,Agency,Phone,Mobile
    },
    agencyGroupName: consortiumName,
    agencyGroupCode: agencyDetails.consortiumId,
  };

  const travelAgent = {
    firstName: isInternalUserAutheticated
      ? agentDetails.firstName
      : agencyDetails.firstName,
    lastName: isInternalUserAutheticated
      ? agentDetails.lastName
      : agencyDetails.lastName,
    userName: isInternalUserAutheticated
      ? agentDetails.username
      : agencyDetails.username,
  };

  let flightsArr = [];
  let seatArr = [];

  if (segment && segment.length > 0) {
    segment.map((obj) => {
      let data = Object.values(obj);

      let flightData = data && data[0] && data[0].flights;
      flightsArr = [...flightsArr, ...flightData];
    });
  }

  const [status, setStatus] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [selectedSeatArr, setSelectedSeat] = useState([]);

  useEffect(() => {
    if (!bookingWithQuote) {
      dispatch(setBookingDetails({}));
    }
  }, [bookingWithQuote]);

  const pnrApiCall = async () => {
   if(ssoToken !== undefined) return 
    let requestPayload = {
      recordLocator: flightDetail && flightDetail.pnr,
      providerId: providerId,
      organizationId: orgId,
      agencyId: agencyId,
      agencyGroupId: agencyGroupId,
      agency: agencyData,
      travelAgent: travelAgent,
    };
    let result = await dispatch(getPnrDetails(requestPayload));
    
    if(result =="error"){
      setLoad(true);
      setTimeoutError(true);
    }

    if (result.error) {
      history.push("/HeaderMyFiles");
      setLoad(true);
    } else if (result) {
      // setLoad(true);
      setStatus(
        result && result.bookingStatusDetail && result.bookingStatusDetail.code
      );
      if(result?.bookingDetails?.isPaymentDoneManually){setIsPaymentDoneManually(result?.bookingDetails?.isPaymentDoneManually)}
      if(result?.bookingDetails?.isItineraryChanged && result?.bookingStatusDetail?.code === "NP"){
        setIsItineraryModified(result?.bookingDetails?.isItineraryChanged)
      }
      if (result.bookingDetails && result.bookingDetails.segments) {
        dispatch(setBookingItinenary(result.bookingDetails));
      }
      if(result?.bookingDetails?.isPQupdatedManually || !result?.bookingDetails?.itineraryFare){
        result?.bookingDetails?.isPQupdatedManually && setIsPQupdatedManually(true);
        !result?.bookingDetails?.itineraryFare && setIitineraryFareNotPresent(true)
        // setLoad(true)
        toast.error(t("The Price Summary details and Payment"), { position: toast.POSITION.TOP_CENTER, autoClose: true });
      }
      for(let index = 0; index < result?.bookingDetails?.warnings?.length; index++){
        if(result?.bookingDetails?.warnings[index]?.message?.includes(`Passenger Name couldn't be matched with SSR Docs so unable to display Gender & DoB. Please contact us to confirm the details in the PNR`))
        {
          setDobGenderError(false);
          // setLoad(true)
          toast.error(t("Passenger Name couldn't"), { position: toast.POSITION.TOP_CENTER, autoClose: true });
          break;
        }else{
          setDobGenderError(false);
        }
      }
      // setLoad(true)
      setIsUpdate(true)
      let verifyPnrResult = await callVerifyPNR(result)
      
      
      let defaultTransaction;
      if (verifyPnrResult?.status == 'Success') {
        toast.dismiss();
        defaultTransaction = verifyPnrResult?.defaultTransactionDetails;
        dispatch(setItenaryData(verifyPnrResult?.itineraryFare))
        dispatch(setItinaryFaresType(verifyPnrResult?.itineraryFare?.type))
      } else {
        defaultTransaction = result?.defaultTransactionDetails;
        if(result?.bookingDetails?.itineraryFare){
          dispatch(setItenaryData(result?.bookingDetails?.itineraryFare))
          dispatch(setItinaryFaresType(result?.bookingDetails?.itineraryFare?.type))
        }
      }
      dispatch(setMarkupApplied(true))
      for (let index = 0; index < defaultTransaction?.length; index++) {
        defaultTransaction[index]["agencyMarkups"] = result?.bookingDetails?.agencyMarkups
        defaultTransaction[index]["itineraryFare"] = result?.bookingDetails?.itineraryFare
        if (defaultTransaction[index]?.type == "CreditCard") {
          dispatch(setPaymentRules(defaultTransaction[index]))
          dispatch(setInitialPaymentRules(defaultTransaction[index]))
        }
        else if (defaultTransaction[index]?.type == "Cheque") {
          dispatch(setPaymentRulesForCheque(defaultTransaction[index]))
          dispatch(setInitialPaymentRulesChq(defaultTransaction[index]))
        }
        else {
          dispatch(setPaymentRules(defaultTransaction[index]))
          dispatch(setInitialPaymentRules(defaultTransaction[index]))
        }
      }
      setFetchLatestFare(false)
      setLoad(true)
    }
  // };

      if (result.bookingDetails && result.bookingDetails.segments) {
        dispatch(setBookingItinenary(result.bookingDetails))
      }    
    }



  const callVerifyPNR = async (result) => {
    let status = result?.bookingStatusDetail?.code;

    let currentDateTime = new Date();
    let currentDateAndTime = moment(currentDateTime).format("YYYY-MM-DD");
    // let bookingDateAndTime = bookingDate && moment(bookingDate).format("YYYY-MM-DD")
    let bookingDateAndTime = result?.bookingDate && moment(result?.bookingDate).format("YYYY-MM-DD")

    if (status == "NP" && (!saveCalculatorFlow) && (result.providerName !== "NDC") && (result.providerName !== "Sabre_NDC")) {
      //if (result?.verifiedFareDate ? moment(result?.verifiedFareDate).format("YYYY-MM-DD") != currentDateAndTime : true) {
        setFetchLatestFare(true)
        // toast.info(t("Please wait while we fetch the latest fare."), { position: toast.POSITION.TOP_CENTER, autoClose:true, closeButton: false });

        let result = await dispatch(getRepriceBeforePayment(recordLocator)) 
        if (result == "error") {
          toast.error(t("143"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          history.push("/HeaderMyFiles");
        } else if (result.status == "Success") {
          if (result?.fareChange?.status && (taxIncreasedStatusArray.includes(result?.fareChange?.status))) {
            toast.dismiss();
            setVerifyPnrPopUp(true) 
            setFetchLatestFare(false)
            setLoad(true)
            return result;
          }
        } else if (result.status == "Failed") {
          setFetchLatestFare(false)
          setLoad(true)
          // setIsVerifyPNRFailed(true)
          return result;
        }
      //}
    }
    setFetchLatestFare(false)
    setLoad(true)
    return undefined
  }
  

  useEffect(() => {
    pnrApiCall();
    dispatch(setSeat([]));
    dispatch(setPrevSeat([]));
    dispatch(setEditSeat([]));
    dispatch(setSeatMapData([]));
    dispatch(setSaveSeat([]));
    
  }, []);

  


  // let editDataArray = editSeatRecord
  // let addDataArray = []
  // let testData = seat && seat.filter(objNew => editSeatRecord.find(ppData => ppData && ppData.flightNumber === objNew.flightNumber))

  // seat && seat.map(data => {
  //   if (data.isEdit === true) {
  //     editDataArray.push(data)
  //   } else {
  //     addDataArray.push(data)
  //   }

  // })

    //  let editDataArray = []


//This values are used in side the editSeatData Function

  // const isSameUser = (a, b) => a.passengerId == b.passengerId;

  // const onlyInLeft = (left, right, compareFunction) =>
  //   left.filter(leftValue =>
  //     !right.some(rightValue =>
  //       compareFunction(leftValue, rightValue)));

// This Function is never used

  // const editSeatData = () => {

  //   let editDataArrayFinal = [...editDataArray]
  //   editDataArray && editDataArray.map(data => {

    
  //     let count = passengerData && passengerData.length

  //     let filterSeatEditData = editDataArray && editDataArray.filter(editVal => editVal.flightNumber == data.flightNumber)
     
  //     let filterSeatData = seat && seat.filter(editVal => editVal.flightNumber == data.flightNumber)
  //     if (filterSeatEditData.length < count) {

  //       // Get items that only occur in the left array,
  //       // using the compareFunction to determine equality.

  //       const onlyInA = onlyInLeft(filterSeatData, filterSeatEditData, isSameUser);
  //       // const onlyInB = onlyInLeft(onlyInA, filterSeatAddData, isSameUser);
  //       editDataArrayFinal.push(...onlyInA)

  //     }

  //   })

  //   return editDataArrayFinal

  // }

  useEffect(() => {
    let flag = false;
    if (passengerData && bookingItinenaries.segments) {

      // let itinen = bookingItinenaries.segments;
      // let PassportMandatory = isPassportMandatory
      passengerData.map((obj, i) => {
        if (isPassportMandatory) {
          if (obj.passports.length == 0) {
            flag = true;
          }
        }
      });
      dispatch(setIsPassportMandatory(flag))
    }
  }, [bookingItinenaries, passengerData,isPassportMandatory])

  useEffect(() => {
    if (passengerData && passengerData.length > 0) {
      passengerData.map((passengerObj) => {
        passengerObj.addOns &&
          passengerObj.addOns.map((obj) => {
            if (obj.seat && Object.keys(obj.seat).length > 0) {
              let newObj = {
                flightNumber: obj.flightNumber,
                seat: obj?.seat?.seatNumber,
                amount: obj?.seat?.totalAmount?.amount,
                currency: obj?.seat?.totalAmount?.currency,
                isEdit: false,
              };
              if (passengerObj.id) {
                newObj.passengerId = passengerObj.id;
              }

              seatArr = [...seatArr, newObj];
            }
          });
      });
      let validInfo = false;
      let validationFlagArr = []
      if (!dobGenderError) {
        passengerData.map((obj, i) => {
          let flag=false;
          if (!(!!obj.gender)) {
            flag = true;
          }
          if (!(!!obj.dateOfBirth)) {
            flag = true;
          }

          let dobFlag = dobAgeCheckValidation(tripTypeFlowFrom, allData, tripData, obj.dateOfBirth, obj.paxType)
          if(dobFlag){
            flag=dobFlag
          }

          if (i == 0 && obj?.contact?.phones?.length == 0) {
            flag = true
          }
          if (i == 0 && obj?.contact?.emails?.length == 0) {
            if (obj?.contact?.phones[0]?.type != "Mobile") {
              flag = true
            }
          }
          console.log("passengerValidation1",i,flag,dobFlag)
          validationFlagArr.push(flag)
        });
      }
      if (validationFlagArr.includes(true)) {
        validInfo = true
      }
      setNonGigiPNRCheck(validInfo)
      setValidInfo(validInfo)
    }

    if (seatArr.length > 0) {
      setEditSeatStatus(true);
    }
    setSelectedSeat(seatArr);
    dispatch(setSeat(seatArr));
    dispatch(setPrevSeat(seatArr));
  }, [passengerData]);

  const setLoads = (status) => {
    if (status === true) {
      pnrApiCall();
    } else {
      setLoad(false);
    }
  };

  const setEditStatus = (status) => {
    setEdit(status);
  };

  const updateSeat = async (seatData) => {
    //setSelectedSeat(editSeat)
    setAddedSeatList(seat);
    let newData = JSON.parse(JSON.stringify(seat));

    dispatch(setPrevSeat(newData));


    let arr = [];
    let addData = []

    let editData = []


    let addArr = []


    passengerData &&
      passengerData.map((passengerObj, passengerIndex) => {
        let addOnArr = []
        if (seat && seat.length > 0) {
          seat.map((dataObj, i) => {
            if (passengerObj.id === dataObj.passengerId) {
              let index = addPassenger.findIndex(function (element) {
                return element.passengerId == dataObj.passengerId;
              });

              let flightData = passengerData[passengerIndex]?.addOns;
              let flightIndex = flightData?.findIndex(
                (flightObj) => flightObj.flightNumber == dataObj.flightNumber
              );
              let newFlightIndex = flightsArr.findIndex(
                (flightObj) => flightObj.number == dataObj.flightNumber
              );

              let obj =
                flightData &&
                flightData[flightIndex] &&
                flightData[flightIndex]["seat"];

              if (obj === undefined || Object.keys(obj).length === 0) {
                if (index === -1) {
                  let newSeat = {};
                  if (dataObj.amount === 0 || dataObj.amount === undefined) {
                    newSeat = {
                      seatNumber: dataObj.seat,
                      aLaCarteOfferItemID: dataObj?.aLaCarteOfferItemID
                    };
                  } else {
                    newSeat = {
                      seatNumber: dataObj.seat,
                      totalAmount: {
                        amount: dataObj.amount,
                        currency: dataObj.currency,
                      },
                      aLaCarteOfferItemID: dataObj?.aLaCarteOfferItemID
                    };
                  }

                  let carrier = flightsArr[newFlightIndex]?.carrier;

                  let obj = {
                    flightNumber: dataObj.flightNumber,
                    carrier: carrier,
                    seat: newSeat,
                  };
                  addOnArr.push(obj);
                  let addId;

                  let newArr = addArr.filter(
                    (obj) => obj.passengerId === passengerObj.id
                  );
                  if (newArr.length === 0) {
                    addId =
                      addArr.length === 0
                        ? `1`
                        : `${Number(addArr[addArr.length - 1].id) + 1}`;
                  } else {
                    addId = addArr[addArr.length - 1].id;
                  }
                  addArr.push({
                    passengerId: dataObj.passengerId,
                    id: addId,
                    addOns: addOnArr,
                  });

                  addArr.map((newObj) => {
                    if (newObj.passengerId === dataObj.passengerId) {
                      let currentIndex = addData.findIndex(
                        (data) => data.passengerId === dataObj.passengerId
                      );

                      if (currentIndex === -1) {
                        addData.push({ ...newObj });
                      } else {
                        addData.splice(currentIndex, 1, { ...newObj });
                      }
                    }
                  });

                  setAddPassenger(addData);
                }


              }
              else {        
                  
                
                let editDataFilterAll = seat && seat.filter(objNew => editSeatRecord.find(ppData => ppData && ppData.flightNumber === objNew.flightNumber))

                let editDataFinal = editDataFilterAll;
                // let pp = editDataFinal.filter( (ele, ind) => ind === editDataFinal.findIndex( elem => elem.passengerId === ele.passengerId && elem.flightNumber === ele.flightNumber))

                let selectedSeatArray = [];

                editDataFinal &&
                  editDataFinal.map((editSeat) => {
                    let newSeatEdit = {};
                    if (
                      editSeat.amount === 0 ||
                      editSeat.amount === undefined
                    ) {
                      newSeatEdit = {
                        seatNumber: editSeat.seat,
                        aLaCarteOfferItemID: editSeat?.aLaCarteOfferItemID
                      };
                    } else {
                      newSeatEdit = {
                        seatNumber: editSeat.seat,
                        totalAmount: {
                          amount: editSeat.amount,
                          currency: editSeat.currency,
                        },
                        aLaCarteOfferItemID: dataObj?.aLaCarteOfferItemID
                      };
                    }
                    let carrierArray =
                      flightsArr &&
                      flightsArr.filter(
                        (obj) => obj.number === editSeat.flightNumber
                      );
                    let carrier =
                      carrierArray &&
                      carrierArray[0] &&
                      carrierArray[0].carrier;
                    let obj = {
                      flightNumber: editSeat.flightNumber,
                      carrier: carrier,
                      seat: newSeatEdit,
                    };

                    let editId;
                    let newArr =
                      selectedSeatArray &&
                      selectedSeatArray.filter(
                        (obj) => obj.passengerId === editSeat.passengerId
                      );
                    if (newArr.length === 0) {
                      editId =
                        selectedSeatArray.length === 0
                          ? `1`
                          : `${
                              Number(
                                selectedSeatArray[selectedSeatArray.length - 1]
                                  .id
                              ) + 1
                            }`;
                    } else {
                      editId =
                        selectedSeatArray[selectedSeatArray.length - 1].id;
                    }

                    selectedSeatArray.push({
                      passengerId: editSeat.passengerId,
                      id: editId,
                      addOns: [obj],
                    });
                  });

                // new test
                let outputEditSeat = [];
                selectedSeatArray.forEach(function (item) {
                  var existing = outputEditSeat.filter(function (v, i) {
                    return v.passengerId == item.passengerId;
                  });
                  if (existing.length) {
                    var existingIndex = outputEditSeat.indexOf(existing[0]);
                    outputEditSeat[existingIndex].addOns = outputEditSeat[
                      existingIndex
                    ].addOns.concat(item.addOns);
                  } else {
                    if (typeof item.addOns == "string")
                      item.addOns = [item.addOns];
                    outputEditSeat.push(item);
                  }
                });

                editData = outputEditSeat;
                setEditPassenger(outputEditSeat);
              }
            }
          });
        }
      });

    let obj = {};
    if (addData.length > 0) {
      obj.addOperations = addData;
    }
    if (editData.length > 0) {
      obj.updateOperations = editData;
    }

    let updatePassengerArr = [];

    let allPassengerArr = [...editData, ...addData];

    passengerData.map((obj) => {
      let newObj = {
        id: obj.id,
        paxType: obj.paxType,
        firstName: obj.firstName,
        lastName: obj.lastName,
        middleName: obj.middleName,
        prefix: obj.prefix,
      };
      let index = allPassengerArr.findIndex(
        (passenegrObj) => passenegrObj.passengerId == obj.id
      );
      if (index >= 0) {
        updatePassengerArr.push(newObj);
      }
    });

    if (updatePassengerArr.length === 0) return;

    const travelAgent = {
      firstName: isInternalUserAutheticated
        ? agentDetails.firstName
        : agencyDetails.firstName,
      lastName: isInternalUserAutheticated
        ? agentDetails.lastName
        : agencyDetails.lastName,
      userName: isInternalUserAutheticated
        ? agentDetails.username
        : agencyDetails.username,
    };

    let payload = {
      travelAgent: travelAgent,
      providerId: providerId,
      organizationId: orgId,
      agencyId: agencyId,
      agencyGroupId: agencyGroupId,
      recordLocator: flightDetail.pnr,
      passengers: updatePassengerArr,
      pnrOperations: obj,
    };

    setIsUpdate(false);
    let result = await dispatch(updatePnr(payload));

    // seat status from update pnr
    if (result && result.pnrOperations) {
      setUpdateOperations1(result.pnrOperations);
      dispatch(setEditSeatRecord([]));
    }

    let str = " ";
    if (result) {
      pnrApiCall();
    }
    if (
      result.pnrOperations &&
      Object.keys(result.pnrOperations).length === 0
    ) {
      // return toast.error(`Edit passenger data is failed to update`, { position: toast.POSITION.TOP_CENTER, autoClose: true });
    }
    if (result && result.pnrOperations) {
      let isupdatefailed = false;
      str = str + t(`Failed to Update`) + ` `;
      let username = "";
      if (
        result.pnrOperations.updateOperations &&
        result.pnrOperations.updateOperations.length > 0
      ) {
        let updateData = result.pnrOperations.updateOperations;
        for (let i = 0; i < updateData.length; i++) {
          let matchedObj =
            result &&
            result.passengers.find((obj) => {
              return obj[`id`] === updateData[i].passengerId;
            });
          let username = matchedObj.firstName + ` ` + matchedObj.lastName;
          if (
            updateData[i].addOnsUpdates &&
            updateData[i].addOnsUpdates.length > 0
          ) {
            for (let k = 0; k < updateData[i].addOnsUpdates.length; k++) {
              if (
                updateData[i].addOnsUpdates[k].failedUpdates &&
                updateData[i].addOnsUpdates[k].failedUpdates.length > 0
              ) {
                str =
                  str +
                  updateData[i].addOnsUpdates[k].failedUpdates.join() +
                  ` ` +
                  `  having flightNo ${updateData[i].addOnsUpdates[k].flightNumber} ,`;
                isupdatefailed = true;
              }
            }
          }
          str = str + `of ${username}`;
        }
      }
      if (
        result.pnrOperations.addOperations &&
        result.pnrOperations.addOperations.length > 0
      ) {
        let addData = result.pnrOperations.addOperations;
        for (let i = 0; i < addData.length; i++) {
          let matchedObj =
            result &&
            result.passengers.find((obj) => {
              return obj[`id`] === addData[i].passengerId;
            });
          let username = "";
          if (matchedObj) {
            username = matchedObj.firstName + ` ` + matchedObj.lastName;
          }

          if (addData[i].addOnsUpdates && addData[i].addOnsUpdates.length > 0) {
            for (let k = 0; k < addData[i].addOnsUpdates.length; k++) {
              if (
                addData[i].addOnsUpdates[k].failedUpdates &&
                addData[i].addOnsUpdates[k].failedUpdates.length > 0
              ) {
                str =
                  str +
                  addData[i].addOnsUpdates[k].failedUpdates.join() +
                  ` ` +
                  ` having flightNo ${addData[i].addOnsUpdates[k].flightNumber} ,`;
                isupdatefailed = true;
              }
            }
          }
          str = str + `of ${username}`;
        }
      }

      if (
        result.pnrOperations.deleteOperations &&
        result.pnrOperations.deleteOperations.length > 0
      ) {
        let addData = result.pnrOperations.deleteOperations;
        for (let i = 0; i < addData.length; i++) {
          let matchedObj =
            result &&
            result.passengers.find((obj) => {
              return obj[`id`] === addData[i].passengerId;
            });

          if (matchedObj) {
            let name = matchedObj.firstName + ` ` + matchedObj.lastName;
            username = name === username ? "" : name;
          }

          if (addData[i].failedUpdates && addData[i].failedUpdates.length > 0) {
            str = str + addData[i].failedUpdates.join() + `  ,`;

            isupdatefailed = true;
          }
          if (addData[i].addOnsUpdates && addData[i].addOnsUpdates.length > 0) {
            for (let k = 0; k < addData[i].addOnsUpdates.length; k++) {
              if (
                addData[i].addOnsUpdates[k].failedUpdates &&
                addData[i].addOnsUpdates[k].failedUpdates.length > 0
              ) {
                str =
                  str +
                  addData[i].addOnsUpdates[k].failedUpdates.join() +
                  ` ` +
                  ` having flightNo ${addData[i].addOnsUpdates[k].flightNumber} ,`;
                isupdatefailed = true;
              }
            }
          }
          if (username.trim().length > 0) {
            str = str + `of ${username}`;
          }
        }
      }

      if (isupdatefailed) {
        // toast.error(`${str}`, { position: toast.POSITION.TOP_CENTER, autoClose: true });
      } else {
        // toast.success(`Passenger seats updated successfully`, { position: toast.POSITION.TOP_CENTER, autoClose: true });
      }
    }
  };

  const clearData = () => {
    setEditPassenger([]);
    setAddPassenger([]);
  };

  let showIternary = false;

  if (
    bookingItinenaries.hasOwnProperty("segments") &&
    bookingItinenaries.segments.length !== 0
  ) {
    showIternary = true;
  } else if (bookingItinenaries.length !== 0) {
    showIternary = true;
  }

  useEffect(() => {

    if (passengerData && passengerData.length > 0 && isUpdate) {
      // getPaymentRulesApi()
    }

  }, [isUpdate])


  const getPaymentRulesApi = async () => {
    
    let agencyArr = []
    if (adultsCount > 0) {
      let obj = {
        paxType: PassengerType.ADULT,
        quantity: adultsCount,
        perPaxAmount: {
          amount: adultAmount ? adultAmount : 0,
          currency: currency
        }
      }
      agencyArr.push(obj)
    }
    if (childCount > 0) {
      let obj = {
        paxType: PassengerType.CHILD,
        quantity: childCount,
        perPaxAmount: {
          amount: childAmount ? childAmount : 0,
          currency: currency
        }
      }
      agencyArr.push(obj)
    }

    if (infantCount > 0) {
      let obj = {
        paxType: PassengerType.INFANTINLAP,
        quantity: infantCount,
        perPaxAmount: {
          amount: infantAmount ? infantAmount : 0,
          currency: currency
        }
      }
      agencyArr.push(obj)
    }

    let arr = [];
    let passengerRef = [];
    for (let i = 1; i <= passengerData.length; i++) {
      if (i <= adultsCount) {
        arr = [
          ...arr,
          {
            id: `${i}`,
            paxType: PassengerType.ADULT,
          },
        ];
        passengerRef.push(`${i}`);
      } else if (i > adultsCount && i <= adultsCount + childCount) {
        let j = i - adultsCount;
        arr = [
          ...arr,
          {
            id: `${i}`,
            paxType: PassengerType.CHILD,
          },
        ];
        passengerRef.push(`${i}`);
      } else {
        let j = i - (adultsCount + childCount);
        arr = [
          ...arr,
          {
            id: `${i}`,
            paxType: PassengerType.INFANTINLAP,
          },
        ];
        passengerRef.push(`${i}`);
      }
    }

    let reqPayload = {
      recordLocator: recordLocator,
      fareType: fareTypeFromPnr,
      passengers: arr,
      agencyMarkups: agencyArr,
      payments: [
        {
          passengerRefs: passengerRef,
          type: "CreditCard",
        },
      ],
    };
    let result = dispatch(getPaymentRules(reqPayload, "CreditCard"));
    if (result) {
      reqPayload = {
        recordLocator: recordLocator,
        fareType: fareTypeFromPnr,
        passengers: arr,
        agencyMarkups: agencyArr,
        payments: [
          {
            passengerRefs: passengerRef,
            type: "Cheque",
          },
        ],
      };

      dispatch(getPaymentRules(reqPayload, "Cheque"));
    }
  };
  let fareType =
    bookingDetail && bookingDetail.fareType;

    const setValidInfoFunc=(val)=>{
      setValidInfo(val);
    }

    const setSaveDetailsFunc=(val)=>{
      let value=counter;
        setCounter(value+1);
        setSaveDetails(val);      
    }

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <>
      <Header setClass="container-fluid" show="d-none" />
      {isload ? <BookingProvider>
        <BookingFor />
        {timeOutError ?
          <div className="booking-main container bd-main-container">
            <div className="seat-udate-status">
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              <strong>Technical error has occurred. Unable to fetch booking details.</strong>
            </div>
          </div>

        :
        <>
            
        <div className="booking-main">
              
          <div className="container bd-main-container">
                {pnrBookingDetails &&
                  pnrBookingDetails.cancellationStatus !== "Cancelled" &&
                  pnrBookingDetails.bookingStatusDetail &&
                  pnrBookingDetails.bookingStatusDetail.code !== "CR" &&
                  pnrBookingDetails.bookingStatusDetail.code !== "NTC" &&
                  pnrBookingDetails.bookingStatusDetail.code !== "TC" &&
                   cancellationFlag &&
                    pnrBookingDetails?.onlineCancellationStatus === "NotAllowed" &&
                      <div className=" quotenotehold"><div className="col-lg-12 quotenotedivhold"><div><span><i className="fa fa-exclamation-circle" aria-hidden="true"></i></span> </div><div>
                      <p className="quotepara">{t("We Are Sorry But Online Cancellation For This File")} {recordLocator} {t("Is Not Allowed. Please Contact Us.")}
                      </p></div></div></div>
                  }
            <div className="row  bd-main-row">
                <div className="col-lg-12">
                  <ConfirmationHead bookingDate={bookingDate} from="confirmation" isInternalUserAutheticated={isInternalUserAutheticated}/>
                </div>
            </div>

            <div className="row  bd-main-row">

              <div className="col-lg-8 bd-main-col">
               
                {flightDetail && flightDetail.bookingStatus && (flightDetail.bookingStatus.code === "NP" || flightDetail.bookingStatus.code === "SC") &&
                
                    <BookingQuoteNote bookingDate={bookingDate} />
                    
                 
                }

                {
                  (!bookingData.segments || (bookingData.segments && bookingData.segments.length === 0))
                  ?
                 
                    <div className="bd-itinerary-sec bd-confirmation-sec">
                      <div className="quotenote mb-3">
                        <div className="col-lg-12 quotenotediv reprice">
                          <div>
                            <span>
                              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            </span>{" "}
                          </div>
                          <div>
                            <p>
                               {t('1006')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                  :
                    // (!bookingData.itineraryFare || (bookingData.itineraryFare && bookingData.itineraryFare.length === 0)) ?
                    // <div className="bd-itinerary-sec bd-confirmation-sec">
                    //     <div className="quotenote mb-3">
                    //       <div className="col-lg-12 quotenotediv reprice">
                    //         <div>
                    //           <span>
                    //             <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                    //           </span>{" "}
                    //         </div>
                    //         <div>
                    //           <p>
                    //             {t("A technical issue occurred, please contact us")}
                    //           </p>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // :
                  <>
                       
                       {((bookingStatus && bookingStatus.code === "NP") ||
                            (bookingStatus && bookingStatus.code === "BA") ) && !ticketableDateCheck &&
                          <div className="seat-udate-wrapper">
                          <div className="seat-udate-status">
                                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                <strong>{t('1007')}</strong>                
                          </div>
                          </div>
                        }

                        { validInfo &&
                          <div>
                            <div className="seat-udate-status">
                              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                              <strong>{t("Please Fill All The Mandatory Fields.")}</strong>
                            </div>
                          </div>
                        }

                        { isItineraryModified &&
                          <div>
                            <div className="seat-udate-status">
                              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                              <strong>{t("We are sorry, payment cannot be applied to this booking due to a technical issue. Please contact us for assistance.")}</strong>
                            </div>
                          </div>
                        }

                      <div className="bd-itinerary-sec bd-confirmation-sec">
                        {(tripTypeValue === TripType.MULTICITY) && showIternary &&
                          <BookingItinenaryMulticity itinenary={bookingItinenaries} recordLocator={recordLocator} from="confirmation" />
                        }
                        {(tripTypeValue === TripType.ONEWAY) && showIternary &&
                          <BookingItineraryOneway itinenary={bookingItinenaries} recordLocator={recordLocator} from="confirmation" />
                        }
                        {(tripTypeValue === TripType.ROUNDTRIP || tripTypeValue === TripType.OPENJAW && showIternary) &&
                          <BookingItineryDetails itinenary={bookingItinenaries.segments} recordLocator={recordLocator} from="confirmation" />
                        }
                        {/* <BookingTravelAgent /> */}
                      </div>
                    
                      <BookingPassengerDetails
                        updateOperations={updateOperations1}
                        isUpdate={isUpdate}
                        selectedSeatArr={selectedSeatArr}
                        setEditStatus={(status) => setEditStatus(status)}
                        updateSeat={(seat) => updateSeat(seat)}
                        clearData={clearData} 
                        validInfo={validInfo}
                        setValidInfoFunc = {(val)=>setValidInfoFunc(val)}
                        nonGigiPNRCheck = {nonGigiPNRCheck}
                        saveDetails = {saveDetails}
                        setSaveDetailsFunc = {(val)=>setSaveDetailsFunc(val)}
                        dobGenderError={dobGenderError}
                        counter={counter}
                        />
                    
                      {(fareType === "Land Net"  || fareType === "Cruise Net") &&
                        <div><BookingNumberForLandCruise fareType={fareType} from="confirmation"/></div>
                      }
                      <BookingTravelAgentInfo from="confirmation"/>
                      <div >{!fetchLatestFare && <Markup from="confirmation" show={(bookingStatus && (bookingStatus?.code == "NP" || bookingStatus.code === "SC") && paymentTypeDetails && paymentTypeDetails.length === 0)?true:false}/>}</div>
                     
                      <div className="row">
                        {/* <div className="col-12"> */}
                          {<BookingConfirmationButton isLoad={(status) => setLoads(status)} prevState={prevState} validInfo={validInfo} setSaveDetailsFunc={(val) => setSaveDetailsFunc(val)} isEdit={isEdit} isPaymentDoneManually={isPaymentDoneManually} isPQupdatedManually={isPQupdatedManually} verifyPnr={fetchLatestFare} itineraryFareNotPresent={itineraryFareNotPresent} isItineraryChanged={isItineraryModified} />}
                        {/* </div> */}
                      </div>
                  </>
                }
              </div>
              <div className="col-lg-4 bd-main-col">
                  <div className="bd-priceSummary-sticky">
                      {(paymentTypeDetails && paymentTypeDetails.length === 0) ?

                        <>
                          {(!isPQupdatedManually && !isItineraryModified && !itineraryFareNotPresent) ?
                            
                            <div className="bd-leftSection-scroll">
                              {configuration.showCombineFareBreakdown ?
                                <div className="pricesummary">
                                  <div className="priceinner row">
                                    <div className="col-md-12 bd-main-col pricecol">
                                      <DuffelPriceSummary fromBookingConfirmation={true} />
                                    </div>
                                  </div>
                                </div>
                                :

                                <BookingConfirmationPrice statusCode={bookingStatus?.code} payableBreakDownFlag={true} />
                              }
                            </div>
                            :null
                            }
                        </>
                        :
                        priceValue &&
                        <>
                          {(!isPQupdatedManually && !isItineraryModified && !itineraryFareNotPresent) ? 
                          <>
                            {configuration.showCombineFareBreakdown ?
                            <div className="pricesummary">
                              <div className="priceinner row">
                                <div className="col-md-12 bd-main-col pricecol">
                                  <DuffelPriceSummary fromBookingConfirmation={true} />
                                </div>
                              </div>
                            </div>
                            :
                            <BookingConfirmationPrice statusCode={bookingStatus?.code} payableBreakDownFlag={false} />}
                            </>
                            :
                            null

                            }

                          {!isPaymentDoneManually && !isItineraryModified && <BookingPaymentSummary passengerData={passengerData} paxFares={paxFares} paymentTypeDetails={(!isDuffleFlow && expandedPaymentDetails) ? expandedPaymentDetails : paymentTypeDetails} />}
                        </>

                      }
                  </div>
                
              </div>
            </div>

            {/* {(!bookingData.itineraryFare || (bookingData.itineraryFare && bookingData.itineraryFare.length === 0)) ||
                  (!bookingData.segments || (bookingData.segments && bookingData.segments.length === 0)) ?
              ""
            : */}
            
            {/* } */}
          </div>
        </div></>}



      </BookingProvider> :
        <div className="col-md-12 text-center mt-3" style={{height:"50vh"}}>
          <SpinnerLoader />
          <div className='new-loader-text'>
          {fetchLatestFare ?
            t("GiGi is verifying the taxes and updating the values in your price summmary.")
            :
            t("Please wait while GiGi is retrieving your booking data.")
          }
          </div>
        </div>}
        
        <Dialog
             fullWidth
             maxWidth="sm"
             open={verifyPnrPopUp}
             keepMounted
             aria-labelledby="alert-dialog-slide-title"
             aria-describedby="alert-dialog-slide-description"
             className="fareType-dialog"
         >
        <div className={"recheck-popup"}>
          <div className="recheck-popup-box">
            <span className="rp-close" onClick={() => setVerifyPnrPopUp(false)}>
              &times;
            </span>
            <div style={{"marginBottom":"2rem"}}>
            {t("Please Note that the taxes were recalculated")}
            </div>
            
            <div className="btn-fareChange">
                <button className="btn btn-primary" onClick={() => setVerifyPnrPopUp(false)}>{t("OK")}</button>
            </div>
          </div>
        </div>
        </Dialog>


    </>
 
  );
}
export default BookingConfirmationContainer;



