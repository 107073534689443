import { React, Suspense, lazy, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
// import SpinnerLoader from './components/common/spinner';
// import SearchFormContainerNew from './pages/flights/home/SearchFormContainerNew';
import SsoAuthContainer from "./pages/flights/home/ssoAuthContainer"
import BookingConfirmationContainer from "./pages/flights/bookingConfirmation/BookingConfirmationContainer"
import LoginContainer from './pages/flights/home/LoginContainter'

import { env } from './appConfig';
import queryString from 'query-string'
import { useState } from 'react';
import { ProtectedRoutes } from './ProtectedRoutes';
import IntermediateBookingConfirmation from './pages/flights/bookingConfirmation/IntermediateBookingConfirmation';
import VirtuosoLogin from './pages/flights/home/VirtuosoLogin';
// const BookingConfirmationContainer = lazy(() => './pages/flights/bookingConfirmation/BookingConfirmationContainer') 
const ResultsContainer = lazy(() => import("./pages/flights/results/ResultsContainer"));
const ResultsContainerFlexiDate = lazy(() => import("./pages/flights/results/ResultsContainerFlexiDate"));
const BookingResultContainer = lazy(() => import("./pages/flights/booking/bookingResultContainer"));
const HeaderMyfiles = lazy(() => import("./components/homePageHeader/myFiles/headerMyFiles"));
const SavedQuoteDetails = lazy(() => import("./components/homePageHeader/mySavedQuotes/SavedQuoteDetails"));
const PaymentDetailContainer = lazy(() => import("./pages/flights/paymentDetail/PaymentDetailContainer"));
const MySavedQuotes = lazy(() => import("./components/homePageHeader/mySavedQuotes/MySavedQuotes"));
const BrandedFareContainer = lazy(() => import("./pages/flights/brandedFare/BrandedFareContainer"));
const BookingCancellationContainer = lazy(() => import("./pages/flights/bookingConfirmation/BookingCancellationContainer"));
const CalculatorContainer = lazy(() => import("./pages/flights/home/CalculatorContainer"));
const CalculatorBrandedFareContainer = lazy(() => import("./pages/flights/home/CalculatorBrandedFareContainer"));
const BlockSpaceContainer = lazy(()=> import("./pages/flights/blockSpace/BlockSpaceContainer"));
const BlockSpaceBookingContainer = lazy(()=> import("./pages/flights/blockSpace/BlockSpaceBookingContainer"));
const BlockSpaceConfirmationContainer = lazy(()=> import("./pages/flights/blockSpace/BlockSpaceConfirmationContainer"));
const BlockSpacePaymentContainer = lazy(()=> import("./pages/flights/blockSpace/BlockSpacePaymentContainer"));
const SearchFormContainerNew = lazy(()=> import("./pages/flights/home/SearchFormContainerNew"));


let isHeaderCalled = false;
const history = () => {
	return createBrowserHistory();
};

const VIRTUOSO_CONSTANT = "virtuoso";
let url = window.location.hostname;
// let url = extractDomain("www.virtuoso.com");
window.isVirtuoso = () => {
	return url?.toLowerCase().includes(VIRTUOSO_CONSTANT);
}

// add header footer for external user  
const createHeaderFooterExternalUser = (token, language) => {
	const headerLoader = document.getElementById("headerLoader");
	const footerLoader = document.getElementById("footerLoader");
	const elBody = document.getElementsByTagName('body');
	var xhr = new XMLHttpRequest();
	xhr.open('GET', `${env.REACT_APP_NEW_HEADER_URL}?token=${token}&lang=${language}`, true);
	xhr.onreadystatechange = function () {
		if (this.readyState !== 4) return;
		if (this.status !== 200) {
			headerLoader?.classList?.remove("show");
			return;
		}
		console.log('thisaa',this.status)
		elBody[0].insertAdjacentHTML('afterbegin', this.responseText);
		headerLoader?.classList?.remove	("show");
	};
	xhr.send();
	// FOOTER CODE
	var xhr = new XMLHttpRequest();
	xhr.open('GET', `${env.REACT_APP_NEW_FOOTER_URL}?lang=${language}&token=${token}`, true);
	xhr.onreadystatechange = function () {
		if (this.readyState !== 4) return;
		if (this.status !== 200) {
			footerLoader?.classList?.remove("show");
			return;
		}
		elBody[0].insertAdjacentHTML('beforeend', this.responseText);
		footerLoader?.classList?.remove	("show");
	};
	xhr.send();
}

// remove header footer for external user
const removeFooterExternalUser = (isHeaderFooterAvailable) => {
	const head = document.querySelector("head");
	const body = document.querySelector("body");
	const header = document.querySelector(".travelbrands-header");
	isHeaderFooterAvailable && head.removeChild(isHeaderFooterAvailable);
	if (header) {
		const topImg = document.querySelector("#topImg");
		const footerWrapper = document.querySelector(".travelbrands-footer");
		header && body.removeChild(header);
		topImg && body.removeChild(topImg);
		footerWrapper && body.removeChild(footerWrapper);
	}
}

// HEADER AND FOOTER FOR EXTERNAL USER
function addNewRemoveHeaderFooter(location, token, language) {
	if(window.isVirtuoso()) return;
	const isHeaderFooterAvailable = document.querySelector("#traveBrandHeader");
	const isHeaderFooterAvail = document.querySelector(".travelbrands-header");
	if (location.pathname !== "/login" && !isHeaderFooterAvailable && !isHeaderFooterAvail) {
		createHeaderFooterExternalUser(token, language)
	} else if (location.pathname === "/login" && (isHeaderFooterAvailable || isHeaderFooterAvail)) {
		const head = document.querySelector("head");
		const body = document.querySelector("body");
		const header = document.querySelector("#hdrWrap");
		isHeaderFooterAvailable && head.removeChild(isHeaderFooterAvailable);
		isHeaderFooterAvail && head.removeChild(isHeaderFooterAvail);
		if (header) {
			const topImg = document.querySelector("#topImg");
			const footerWrapper = document.querySelector("#footerWrapper");
			header && body.removeChild(header);
			topImg && body.removeChild(topImg);
			footerWrapper && body.removeChild(footerWrapper);
		}
		window.location.reload()
	}
	if (location.pathname !== "/login" && (isHeaderFooterAvailable || isHeaderFooterAvail)) {
		removeFooterExternalUser(isHeaderFooterAvailable)
		createHeaderFooterExternalUser(token, language)
	}
}

// to create header and footer element for internal user
const createHeaderAndFooterElement = (headerURL, footerURL, isInternalUser) => {
	const headerLoader = document.getElementById("headerLoader");
	const footerLoader = document.getElementById("footerLoader");
	const script = document.createElement('script');
	script.setAttribute('src', headerURL);
	script.setAttribute('id', "traveBrandHeader");

	script.setAttribute('async', '');
	script.onload = function handleScriptLoaded() {
		headerLoader?.classList?.remove	("show");
		console.log('script has loaded');
		// document.getElementById('box')?.textContent = 'Script loaded successfully';
	};

	script.onerror = function handleScriptError() {
		headerLoader?.classList?.remove	("show");
		console.log('error loading script');
	};

	document.head.appendChild(script);

	// FOOTER CODE
	const elBody = document.getElementsByTagName('body');
	var xhr = new XMLHttpRequest();
	xhr.open('GET', footerURL, true);
	xhr.onreadystatechange = function () {
		if (this.readyState !== 4) return;
		if (this.status !== 200) {
			footerLoader?.classList?.remove("show");
			return
		};
		elBody[0].insertAdjacentHTML('beforeend', this.responseText);
		footerLoader?.classList?.remove("show");
	};
	xhr.send();
	if (isInternalUser) {
		let time = 0;
		let tOut = setInterval(() => {
			const parent = document.querySelector("#subNav");
			const gearIcon = document.querySelector("#gear");
			if (parent && gearIcon) {
				parent.removeChild(gearIcon);
				clearTimeout(tOut);
			} else if (time <= 9) {
				time += 1;
			} else {
				clearTimeout(tOut);
			}
		}, 100);
	}
}

// to remove header and footer element for internal user
const removeHeaderFooterElement = (isHeaderFooterAvailable) => {
	const head = document.querySelector("head");
	const body = document.querySelector("body");
	const header = document.querySelector("#hdrWrap");
	head.removeChild(isHeaderFooterAvailable);
	if (header) {
		const topImg = document.querySelector("#topImg");
		const footerWrapper = document.querySelector("#footerWrapper");
		header && body.removeChild(header);
		topImg && body.removeChild(topImg);
		footerWrapper && body.removeChild(footerWrapper);
	}
}

function addRemoveHeaderFooter(location, isInternalUser, language) {
	if(window.isVirtuoso()) return;
	const isHeaderFooterAvailable = document.querySelector("#traveBrandHeader");
	const isNewHeader = document.querySelector(".main-header");
	const headerURL = language === "fr" ? env.REACT_APP_HEADER_URL_FRENCH : env.REACT_APP_HEADER_URL;
	const footerURL = language === "fr" ? env.REACT_APP_FOOTER_URL_FRENCH : env.REACT_APP_FOOTER_URL;
	if (location.pathname !== "/login" && !isHeaderFooterAvailable && !isNewHeader) {
		createHeaderAndFooterElement(headerURL, footerURL, isInternalUser)
	}
	else if (location.pathname === "/login" && isHeaderFooterAvailable) {
		removeHeaderFooterElement(isHeaderFooterAvailable)
		window.location.reload()
	}
	if (location.pathname !== "/login" && isHeaderFooterAvailable) {
		removeHeaderFooterElement(isHeaderFooterAvailable)
		createHeaderAndFooterElement(headerURL, footerURL, isInternalUser)
	}
	
	const rootParent = document.querySelector('#root').parentElement;
	const brTags = rootParent.querySelectorAll('br');

	brTags.forEach(function(br) {
		if (!br.closest('#root') && !br.closest('div')) {
			br.style.display = 'none';
		}
	});

}

window.addRemoveHeaderFooter = addRemoveHeaderFooter
window.addNewRemoveHeaderFooter = addNewRemoveHeaderFooter

function Routes() {
	const location = useLocation();
	const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
	const token = useSelector((state) => state.agentProfile.agentToken);
	const userInfoFromUrl = queryString.parse(location && location.search);
    const newAccess = userInfoFromUrl && userInfoFromUrl?.isNewAccess;
	const newToken = userInfoFromUrl && userInfoFromUrl?.token;
	const newUser = newToken?.includes("XSPlus") || token?.includes("XSPlus");
	const language = useSelector( (state) => state.lang.lang)
	const status = useSelector((state) => state.agentProfile.isAgentAutheticated);
	const [tokenPresent, setTokenPresent] = useState(false)
	const flightType = useSelector((state)=>state.searchForm.flightType)
	const orgId = useSelector((state) => state.agentProfile.orgId);
	useEffect(() => {
		if(window.isVirtuoso()) return;
		if (location.pathname !== "/" || isInternalUserAutheticated) {
		if (newUser == true || tokenPresent) {
			if (token && newUser){
				if(!isHeaderCalled){
					addNewRemoveHeaderFooter(location, token,language)
					isHeaderCalled = true;
				}
			}
		} else if (!tokenPresent) {
			addRemoveHeaderFooter(location, isInternalUserAutheticated, language);
		}
	   }
	}, [location.pathname, token,status]);

	useEffect(() => {
		const newUser = token?.includes("XSPlus");
		if (newUser == true) {
			if (token) {
				setTokenPresent(true)
			}
		} else {
			setTokenPresent(false)
		}
	}, [token])

	useEffect(()=>{
		if(flightType == "CAD"){
			window.selectedDollarValue = '$'
		}
		else{
			window.selectedDollarValue = '$'
		}
	})

	return (
		<Suspense
		fallback={
			<div className="col-md-12 text-center mt-3">
					{/* <SpinnerLoader /> */}
				</div>
			}
		>
			<Switch history={history}>

			{window.isVirtuoso() ? (
				<>
				<Route exact path="/" component={VirtuosoLogin} />
				<Route exact path="/login" component={VirtuosoLogin} />
				<Route exact path="/search" component={SearchFormContainerNew} auth={String(orgId).length} />
				<ProtectedRoutes path="/SearchResults" component={ResultsContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BookingPage" component={BookingResultContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/SearchResultsFlexiDate" component={ResultsContainerFlexiDate} auth={String(orgId).length} />
				<ProtectedRoutes path="/HeaderMyFiles" component={HeaderMyfiles} auth={String(orgId).length} />
				<ProtectedRoutes path="/MySavedQuotes" component={MySavedQuotes} auth={String(orgId).length} />
				<ProtectedRoutes path="/saved-quotes/details" component={SavedQuoteDetails} auth={String(orgId).length} />
				<Route exact path="/booking-confirmation" component={BookingConfirmationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/payment-details" component={PaymentDetailContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/branded-fare" component={BrandedFareContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/booking-cancellation" component={BookingCancellationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/calculator" component={CalculatorContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/branded-fare-calculator" component={CalculatorBrandedFareContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpace" component={BlockSpaceContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpaceBooking" component={BlockSpaceBookingContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpaceConfirmation" component={BlockSpaceConfirmationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpacePayment" component={BlockSpacePaymentContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/intermediate-booking-confirmation" component={IntermediateBookingConfirmation} auth={String(orgId).length} />
				</>
			) : (
				<>
				<Route exact path="/" component={SsoAuthContainer} />
				<Route exact path="/login" component={LoginContainer} />
				<ProtectedRoutes path="/SearchResults" component={ResultsContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BookingPage" component={BookingResultContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/SearchResultsFlexiDate" component={ResultsContainerFlexiDate} auth={String(orgId).length} />
				<ProtectedRoutes path="/HeaderMyFiles" component={HeaderMyfiles} auth={String(orgId).length} />
				<ProtectedRoutes path="/MySavedQuotes" component={MySavedQuotes} auth={String(orgId).length} />
				<ProtectedRoutes path="/saved-quotes/details" component={SavedQuoteDetails} auth={String(orgId).length} />
				<Route exact path="/booking-confirmation" component={BookingConfirmationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/payment-details" component={PaymentDetailContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/branded-fare" component={BrandedFareContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/booking-cancellation" component={BookingCancellationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/calculator" component={CalculatorContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/branded-fare-calculator" component={CalculatorBrandedFareContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpace" component={BlockSpaceContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpaceBooking" component={BlockSpaceBookingContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpaceConfirmation" component={BlockSpaceConfirmationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpacePayment" component={BlockSpacePaymentContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/intermediate-booking-confirmation" component={IntermediateBookingConfirmation} auth={String(orgId).length} />
				</>
			)}
			</Switch>
		</Suspense>
	)
}

export default Routes;